<template>
  <div class="g-tabs">
    <div class="bg" :style="{ 'left': `${current*(100/list.length)}%`,'width': `${100/list.length}%` }"></div>
    <div 
      :class="['item',{'on': index == current}]"
      v-for="(item,index) of list"
      :key="index"
      :style="{ 'width': `${100/list.length}%` }"
    >
      <a href="javascript:;" class="inner" @click="change(index)">{{ item.name }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "gTabs",
  props: {
    list: {
      // name: tab的文字
      // key: tab的key
      type: Array,
      default: () => []
    },
    // 当前位置
    current: {
      type: Number,
      default: 0
    }
  },
  methods: {
    change(index){
      this.$emit('change', index);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* g-tabs */
.g-tabs{
    width: 100%;
    min-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    height: 26px;
    line-height: 26px;
    background: #F7F8FC;
    border-radius: 26px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    -ms-border-radius: 26px;
    -o-border-radius: 26px;
    overflow: hidden;
}
.g-tabs .bg{
    width: 50%;
    height: 26px;
    line-height: 26px;
    background: #0380FF;
    border-radius: 26px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    -ms-border-radius: 26px;
    -o-border-radius: 26px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
}
.g-tabs .item{
    width: 50%;
    float: left;
    position: relative;
    z-index: 2;
}
.g-tabs .inner{
    display: block;
    text-align: center;
    font-size: 14px;
    color: #384450;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    background: transparent;
}
.g-tabs .on .inner{
    color: #fff;
}
/* g-tabs end */
</style>
